.manicure-div{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 158, 37, 0.182);
    position: fixed;
    z-index: 1001;
}

.manicure-form{
    margin: 0 auto;
    width: 80%;
    height: 60vh;
    padding: 1.5em;
    background-color: white;
    border: 1px solid black;
    border-radius: 15px;
    box-shadow: 5px 10px 12px rgb(166, 164, 164);
    position: relative;
    top: 13vh;
    display: flex;
    flex-direction: column;
}

.manicure-form select{
    font-size: 1.4rem;
    height: 1.5em;
    width: 85%;
    margin-top: -0.4rem;
    margin-bottom: 1rem;
}

.manicure-form .close {
    color: red;
    width: 2.3rem;
    height: 2.3rem;
    font-size: 2em;
    border: 1px solid red;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    top: 1rem;
        /* FLEX */
        display: flex;
        justify-content: center;
        align-items: center;
}

.manicure-form div{
    height: 4em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.manicure-form input {
    min-width: 2em;
    max-width: 50%;
}

.manicure-form button {
    margin: 1em auto;
}

.manicure-form .err{
    text-align: center;
    font-size: 13px;
    margin-top: 1em;
    color: red;
    font-weight: 900;
}