.main-expenses{
    width: 100%;
    min-height: 77vh;
    padding: 1rem;
    font-size: 1.3rem;
        /* FLEX */
        display: flex;
        flex-direction: column;
        align-items: center;
}

.main-expenses select{
    width: 90%;
    height: 3rem;
    border: 1px solid black;
    border-radius: 10px;
    font-size: 2rem;
    padding-left: 10px;
}

.main-expenses section{
    width: 100%;
    margin: 1em 0;
    line-height: 2rem;
    border: 1px solid black;
    padding: 5px;
    box-shadow: 2px 2px 3px gray;
}

.main-expenses section h3{
    margin-right: 5px;
    margin-bottom: 0.7rem;
        /* FLEX */
        display: flex;
        justify-content: space-between;
}

.main-expenses section p{
    width: 100%;
        /* FLEX */
        display: flex;
        justify-content: space-between;
}

.main-expenses section p:last-child{
    border-bottom: none;
}

.main-expenses section div div{
    border: 1px solid black;
    margin: 10px 0;
    padding: 3px;
    background-color: rgb(250, 250, 250);
    box-shadow: 1px 1px 2px black;
}

.main-expenses section span{
    font-size: larger;
}

.main-expenses section h4{
    font-size: larger;
    margin-top: 10px;
}

.main-expenses section .total {
    font-weight: 800;
}

.main-expenses section button {
    margin-right: 1rem;
    font-size: 1.3rem;
    padding: 3px 10px;
}

.main-expenses section a {
    color: red;
    text-decoration: underline;
}