.summary {
    width: calc(100% - 1rem);  
    height: 30vh;
    margin: 1rem 0.5rem 0px; 
    border-collapse: collapse;
    overflow-y: scroll;
}

.loading{
    height: 30vh;
    width: 100%;
    display: block;
    margin: 0 auto;
}

.summary-product{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.615);
    border-radius: 8px;
    margin: 1rem 0.5rem;
    padding: 0.4rem;
    display: flex;
    justify-content: space-between;
}

.summary-product .counter{
    width: 2rem;
    border: 1px solid var(--cl-gr);
    border-radius: 8px;
    padding: 1px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.summary-product .counter span{
    font-size: 1.3rem;
    color: var(--cl-gr);
}

.summary-product .counter span:first-child{
    margin-bottom: 1rem;
}

.summary-product .counter span:last-child{
    margin-top: 1rem;
}

.summary-product .counter p{
    margin: -1rem 0;
}

.summary-product .image{
    width: 22vw;
    aspect-ratio: 1/1;
    box-shadow: 0 0 5px rgba(128, 128, 128, 0.161);
}

.summary-product .specs{
    width: 60%;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.summary-product .specs .close{
    margin-left: auto;
    margin-bottom: 0.5rem;
    color: red;
}

.summary-product .specs .price{
    color: black;
    font-size: 1rem;
}

.summary-product .specs .name{
    color: black;
}