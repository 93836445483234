.orders-main {
    width: 100%;
    min-height: 77vh;
    padding: 1rem;
    font-size: 1.3rem;
        /* FLEX */
        display: flex;
        flex-direction: column;
}

.orders-main select{
    font-size: 1.4rem;
    width: 80%;
}

.orders-main section{
    background-color: rgba(255, 255, 255);
    margin-top: 1em;
    margin-bottom: 10px;
    width: 100%;
    line-height: 2rem;
    border: 1px solid black;
    padding: 5px;
    box-shadow: 2px 2px 3px gray;
}

.orders-main section p{
    border-bottom: 1px solid black;
    /* FLEX */
    display: flex;
    justify-content: space-between;
}

.orders-main section p:last-child{
    border-bottom: none;
}

.orders-main .each-order{
    border-bottom: 3px solid black;
    padding-bottom: 1em;
    margin-bottom: 1em;
}

.orders-main .each-order:last-child{
    border-bottom: none;
    padding-bottom: 0em;
    margin-bottom: 0em;
}