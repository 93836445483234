.expense-form {
  width: 100%;
  padding: 1rem;
  /* FLEX */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.expense-form div {
  margin: 0.4rem;
  width: 100%;
  font-size: 18px;
  /* FLEX */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expense-form select {
  font-size: 1.2em;
  width: 100%;
  height: 3.5rem;
  border-radius: 10px;
  padding: 5px;
}

.expense-form button {
  width: 60%;
  height: 3rem;
  margin-top: 1em;
}

.expense-form input {
  max-width: 50%;
  text-align: right;
  font-size: 1em;
}
