.todos{
    background-color: white;
    min-height: 90vh;
    padding: 2rem 1.9rem;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.439);
    display: flex;
    flex-direction: column;
}

.todos h2{
    font-size: 2rem;
    margin-bottom: 1rem;
}

.todos h3{
    font-size: 1.7rem;
    text-align: center;
    margin: 0.5rem 1.3rem 0;
    white-space: nowrap;
}

.todos .horizontal-elector{
    height: 3rem;
    width: 100vw;
    margin-left: -1.9rem;
    margin-bottom: 1rem;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
}

.todos .checks label{
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    padding: 0.4rem 0.6rem;
    margin: 1rem 0;
    box-shadow: 2px 2px 5px black;
    border-radius: 8px;
}

.todos .checks input{
     min-width: 2.5rem;
     margin-right: 1.5rem;
}

.todos button {
    height: 4rem;
    width: 80%;
    margin: auto auto 0;
    font-size: 2rem;
    background-color: var(--cl-dk-gn);
    color: white;
    font-weight: 500;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    border: none;
}