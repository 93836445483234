.main-footer{
    border-top: 1px solid black;
    position: relative;
    bottom: 0px;
    width: 100%;
    height: 10vh;
    padding: 0 1em;
    z-index: 998;
    background-color: white;
        /* FLEX */
        display: flex;
        justify-content: space-between;
        align-items: center;
}

.main-footer p {
    font-size: 1.3em;
}

.main-footer aside{
    color: gray;
    height: 50%;
    font-size: 0.9em;
        /* FLEX */
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-around;
}