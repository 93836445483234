.change-pass {
    height: 77vh;
        /* FLEX */
        display: flex;
        justify-content: center;
        align-items: center;
}

.change-pass form {
    height: 40vh;
    width: 80%;
    padding-top: 1em;
    border: 1px solid black;
    border-radius: 50px;
        /* FLEX */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}

.change-pass input {
    margin: 1em;
    width: 80%;
}

.change-pass button {
    width: 40%;
    margin-top: 1em;
}

.change-pass a {
    margin-top: 0.5em;
}