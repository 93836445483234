.loading{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading img{
    margin-top: 20vh;
    width: 100vw;
}

.loader-container{
    height: 100vh;
    width: 100vw;
    display: flex;
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid var(--cl-gn); /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: auto auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }