.aboutus {
    padding: 1.8rem 1rem;
    background-color: white;
    border-radius: 12px;
    min-height: 91vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aboutus .us, .aboutus .mision {
    margin-bottom: 2rem;
    color: var(--cl-dk-gr);
}

.aboutus h3{
    color: black;
    font-size: 2rem;
    white-space: nowrap; 
}

.aboutus .inner-div{
    display: flex;
    align-items: center;
    margin-bottom: 0.6rem;
}

.aboutus p{
    font-size: 1.4rem;
    text-align: justify;
}

.aboutus .inner-div .line{
    width: 100%;
    height: 1px;
    border-bottom: 1px solid black;
    margin: 0 5px;
}

.aboutus .logo{
    /* margin-top: -2.5rem; */
    /* margin-right: -7rem; */
    width: 50vw;
}