.stock-main {
    width: 100%;
    min-height: 91vh;
    background-color: white;
    border-radius: 12px;
    padding: 2rem 1.5rem;
    /* FLEX */
        display: flex;
        flex-direction: column;
        align-items: center;
}

.stock-section {
    width: 100%;
    margin: 0.7rem 0;
    padding: 0.2rem 1rem;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.459);
    text-align: center;
}

.stock-section p {
    width: 100%;
    margin: 0.2rem 0;
    font-size: 1.1rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}

.stock-modal{
    z-index: 3;
    height: 100vh;
    width: 100vw;
    background-color: var(--cl-gn-dg);
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stock-modal-div{
    text-align: center;
    padding: 1rem 0.8rem;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.43);
    border-radius: 12px;
    height: 40vh;
}

.stock-modal-div h3{
    margin-bottom: 2rem;
    font-size: 2rem;
}

.stock-modal-div button{
    width: 40%;
    height: 2rem;
}

.stock-modal-div .modal-input{
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.stock-modal-div .modal-input input{
    max-width: 30%;
    min-width: 15%;;
}