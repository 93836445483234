.menu-items {
    font-size: 14px;
    width: 100%;
    position: relative;
}

.menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
}

.menu-items > a{
    text-align: left;
    padding: 0.7rem 1rem;
    font-size: 1.7rem;
}

.dropdown {
    position: absolute;
    top: calc(100% + 10px);
    left: 15%;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
    width: 65vw;
    padding: 0.5rem 0;
    list-style: none;
    z-index: 9999;
    background-color: #fff;
    border-radius: 0.5rem;
    display: none;
    color: black;
}

.dropdown.show {
    display: block;
}

.icon{
    color: #fff;
    height: 2rem
}