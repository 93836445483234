.datepicker{
    width: 80vw;
    height: 4rem;
    padding: 0.7rem;
    background-color: var(--cl-gn);
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: space-between;
}
.datepicker .day{
    width: 64%;
    border-radius: 10px;

}
.datepicker .time{
    width: 35%;
    border-radius: 10px;
}

.datepicker .day, .datepicker .time{
    color: var(--cl-dk-gn);
    font-size: 1.1rem;
    font-weight: 600;
    padding-left: 0.2rem;
}