.main-main{
    min-height: 87vh;
    margin-top: 4rem;
    padding: 1em 0;
    display: flex;
    flex-direction: column;
}

.main-main img{
max-width: 100%;
/* height: 60vh; */
border-radius: 8px;
box-shadow: 0 0 10px #0000006a;
margin-bottom: 10px;
}

.sec{
     height: 25vh;
     margin: 1em auto;
     padding: 0 2rem 3rem 0;
     width: var(--normal-width);
     background-size: cover;
     background-position-x: 55%;
     border-radius: 8px;
     box-shadow: 0 0 10px #0000006a;
     /* FLEX */
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
}

.sec span{
    font-size: 2.5rem;
    color: #fff;
    font-weight: bold;
    text-shadow: 0 0 5px #000;
}