.harvest-main{
    width: 100%;
    min-height: 77vh;
    padding: 1rem;
    font-size: 1.3rem;
        /* FLEX */
        display: flex;
        flex-direction: column;
        align-items: center;
}

.harvest-main div{
    width: 100%;
    margin-bottom: 1em;
        /* FLEX */
        display: flex;
        justify-content: space-between;
}

.harvest-main div select{
    font-size: 1.4rem;
    width: 80%;
}

.harvest-main div button, .harvest-main .qr{
    width: 15%;
    font-size: 1.4rem;
}

.harvest-main section{
    margin-bottom: 10px;
    width: 100%;
    line-height: 2rem;
    border: 1px solid black;
    padding: 5px;
    box-shadow: 2px 2px 3px gray;
}

.harvest-main section p{
    border-bottom: 1px solid black;
    /* FLEX */
    display: flex;
    justify-content: space-between;
}

.harvest-main section p:last-child{
    border-bottom: none;
}

.harvest-main .qr{
    width: 45%;
    margin: 1em auto;
}