header {
  padding: 0 1.5em;
}

.main-header{
  width: 100vw;
  height: 9vh;
  color: #ffffff;
    /* FLEX */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-header nav{
    /* FLEX */
    display: flex;
    justify-content: center;
}
  
.main-header .header-middle {
  width: 50%;
  height: 100%;
  text-align: center;
  font-size: calc(85% * 2.5);
  line-height: 1em;
  overflow: hidden;
  color: white;
  font-weight: 1000;
  font-family: theBoldFont;
    /* FLEX */
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-header .open-lo{
  height: 2rem;
  margin-left: 1rem;
}