.admin-main {
    min-height: 77vh;
        /* FLEX */
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
}

.admin-main button{
    height: 3rem;
    width: 60%;
    font-size: 20px;
}