.bill{
    width: 100%;
    min-height: 91vh;
    background-color: rgb(237, 244, 209);
    padding: 0.4rem;
    /* FLEX */
        display: flex;
}

.main-bills{
    width: 100%;
    padding: 0.3rem 0.5rem;
    border: 2px solid black;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}

.main-bills h2{
    font-size: 2.5rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 600;
    margin-bottom: 1rem;
}

.main-bills .date{
    margin: 1rem 1rem 0;
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid black;
}

.main-bills .month, .main-bills .member, .main-bills .species{
    margin: 1rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.main-bills .month label, .main-bills .member label, .main-bills .species label{
    font-size: 1.3rem;
    font-weight: 500;
    white-space: nowrap;
    margin-right: 1rem;
}

.main-bills .month input, .main-bills .member select, .main-bills .species select{
    min-width: 40%;
    width: 80%;
    height: 1.6rem;
    font-size: 1.3rem;
    font-style: italic;
    text-align: center;
    text-overflow: ellipsis;
    border: none;
    border-bottom: 2px solid black;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
    color: black;
}

.main-bills .species-table{
    border: 2px solid black;
    border-radius: 8px;
    padding: 1rem 2rem;
    margin-top: 2rem;
}

.main-bills .species-table .titles{
    margin-top: -1.9rem;
    font-size: 1.3rem;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
}

.main-bills .species-table .titles p{
    background-color: rgb(237, 244, 209);
    padding: 0 3px;
}

.main-bills .species-table .resume{
    padding-top: 0.6rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.main-bills .species-table .resume label{
    margin-left: -1rem;
    width: 60%;
    font-size: 1.3rem;
    font-style: italic;
    font-weight: 500;
    white-space: nowrap;
    margin-right: 1rem;
}

.main-bills .species-table .resume input{
    font-style: italic;
    width: 19%;
    height: 1.6rem;
    font-size: 1.3rem;
    font-style: italic;
    text-align: center;
    text-overflow: ellipsis;
    border: none;
    border-bottom: 2px solid black;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
    color: black;
    margin-right: 1.5rem;
}

.main-bills .upload{
    width: 50%;
    height: 4rem;
    margin: auto 0;
    font-size: 1.8rem;
    background-color: rgba(229, 214, 183, 0.642);
    align-self: center;
}