.confirmation-main{
    background-color: white;
    height: 60vh;
    width: var(--normal-width);
    padding: 2em 1em;
    margin: 1.5em auto;
    margin-top: 5rem;
    text-align: center;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.439);
    /* FLEX */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirmation-main p{
    margin-top: 0.7em;
    color: var(--cl-gn);
    font-weight: bold;
}

.confirmation-main img{
    width: 80%;
    margin-top: 1.4em;
}
.confirmation-main button{
    border: none;
    color: white;
    background-color: var(--cl-gn);
    padding: 10px 14px;
    box-shadow: 0 0 7px #00000042;
    margin-top: auto;
}