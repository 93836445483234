:root{
    --normal-width: 90vw;

    --cl-blk: #03191e;
    --cl-dk-gn:#005745;
    --cl-gn: #0f9044;
    --cl-gn-dg: #0f9045a5;
    --cl-dk-gr: #808080;
    --cl-gr: #D9D9D9;
}

@font-face {
    font-family: inter;
    src: url("/public/Fonts/Inter-VariableFont_slnt\,wght.ttf");
}

.App{
    width: 100%;
    min-height: 100vh;
    position: absolute;
    background-image: URL("/public/background.jpg");
    background-size: cover;
    font-family: inter;
}
