.all-div{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-main{
    background-color: white;
    min-height: 60vh;
    width: var(--normal-width);
    padding: 2.8em 1.9em;
    margin: 1.5em;
    margin-top: 5rem;
    text-align: center;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.439);
}

.login-main form{
    /* FLEX */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-main h2{
    margin-bottom: 1em;
    font-size: 1.5rem;
}

.logo{
    width: 45%;
    aspect-ratio: 1/1;
    margin-bottom: 0.5rem;
}

.login-main input{
    margin: 0.7em 0;
    width: 100%;
    font-size: rem;
    border: none;
    background-color: #D9D9D9;
    box-shadow: 0px 2px 6px #00000045;
    height: 2.5rem;
    color: #808080;
    border-radius: 5px;
}

.login-main button{
    margin-top: 1em;
    padding: 1rem 2.5rem;
    font-size: 1.3rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: white;
    border-radius: 6px;
    border: 0px;
    background-color: #0f9044;
    box-shadow: 0 0 7px #00000061;
}

.login-main p {
    color: red;
}

/* .login{
    height: 100vh;
    padding: 40vh 1rem 0;
    background-image: url("../../../public/Imgs/bienvenido.jpg");
    background-size: cover;
}

.login-header {
    padding: 8em 0.4em 1.5em 1.5em;
    display: flex;
    align-items: center;
}

.login-header img{
    border: 1px solid black;
    margin-right: 1em;
    width: 40%;
    aspect-ratio: 1 / 1;
}

.login-header h1{
    font-size: 1.6rem;
    text-align: center;
    width: 50%;
}

.login-main{
    padding: 7.6rem 0 0 5.5rem;
    height: 100hv;
    text-align: center;
    border-radius: 30px;
    height: 50vh;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.login-main button {
    background-color: #ffffff00;
    border-color: #00000000;
    height: 4rem;
    width: 60%;
    margin-left: -5rem;
    margin-top: 1.7rem;
}

.login-main form{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-main input{
    background-color: rgba(255, 255, 255, 0);
    border: 0px;
    color: #fff;
    font-size: large;
    margin: 1rem;
    margin-bottom: -0.55rem;
    width: 80%;
}
.login-main input::placeholder{
    color: #fff;
}
.login-main input:focus-visible{
    outline: none;
}

.login-main h2{
    margin-bottom: 1.2rem;
}

.login-main p {
    color: red;
} */