.main-logout{
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--cl-gn-dg);
    z-index: 2;
}

.main-logout .modal-logout{
    background-color: white;
    width: 80vw;
    height: 50vh;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.75);
    padding: 0.7rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.main-logout .modal-logout .xmark{
    color: red;
    height: 2rem;
    margin-bottom: auto;
    margin-left: auto;
}

.main-logout .modal-logout p{
    color: var(--cl-dk-gn);
    font-weight: 600;
    font-size: 2rem;
    text-align: center;
    margin-bottom: auto;
}

.main-logout .modal-logout button{
    font-size: 1.5em;
    height: 4rem;
    margin-bottom: 1rem;
    padding: 0 1rem;
    background-color: var(--cl-gn); 
    border: none;
    color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.615);
}