.product-specification {
    width: 100%;
    min-height: 91vh;
    background-color: white;
    border-radius: 12px;
    padding: 2rem 1rem;
}

.product-specification img {
    width: 100%;
    display: inline ;
    height: 84vh;
    object-fit: cover;
}

.product-specification p {
    font-size: 20px;
    margin-top: 1em;    
}

.product-specification .important{
    color: var(--cl-gn);
    font-weight: 600;
    text-align: center;
}