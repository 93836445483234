.workdone-main {
  width: 100%;
  min-height: 77vh;
  padding: 1rem;
  font-size: 1.3rem;
  /* FLEX */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.workdone-main select {
  width: 90%;
  height: 3rem;
  border: 1px solid black;
  border-radius: 10px;
  font-size: 2rem;
  padding-left: 10px;
}

.workdone-main section {
  width: 100%;
  margin: 1em 0;
  line-height: 2rem;
  border: 1px solid black;
  padding: 5px;
  box-shadow: 2px 2px 3px gray;
}

.workdone-main section h3 {
  margin-right: 5px;
  margin-bottom: 0.7rem;
  /* FLEX */
  display: flex;
  justify-content: space-between;
}

.workdone-main section p {
  width: 100%;
  /* FLEX */
  display: flex;
  justify-content: space-between;
}

.workdone-main section p:last-child {
  border-bottom: none;
}

.workdone-main section div div {
  border: 1px solid black;
  margin: 10px 0;
  padding: 3px;
  background-color: rgb(250, 250, 250);
  box-shadow: 1px 1px 2px black;
  display: flex;
  flex-direction: column;
}

.workdone-main section span {
  font-size: larger;
}

.workdone-main section h4 {
  font-size: larger;
  margin-top: 10px;
}

.workdone-main section .total {
  font-weight: 800;
}

.delete {
  padding: 0.5rem 1rem;
  margin-left: auto;
  border: 0px;
  background-color: rgba(255, 0, 0, 0.733);
  box-shadow: 1px 1px 1px gray;
}

.modal {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(6, 54, 0, 0.589);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal div {
  width: 85%;
  border-radius: 10px;
}

.modal-content {
  width: 100%;
  padding: 2rem;
}

.modal-content p {
  font-size: 2rem;
  width: 100%;
  padding: 0.8rem 1rem;
  margin-bottom: 1rem;
}

.modal-content button {
  font-size: 2rem;
  padding: 1rem 0;
  margin: 0.5rem;
}
