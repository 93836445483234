.carta {
    width: 100%;
    min-height: 91vh;
    background-color: white;
    border-radius: 12px;
    padding-top: 2rem;
    /* FLEX */
        display: flex;
        flex-direction: column;
        align-items: center;
}

.agenda{
    margin-top: -1.5rem;
    margin-left: auto;
    margin-right: 5rem;
    font-size: 1.2rem;
    color: var(--cl-gn);
}

.agenda .icon{
    margin-left: 1rem;
    color: var(--cl-gn);
}

.product {
    margin: 15px;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 0 10px #0000003c;
    width: 90vw;
        /* FLEX */
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px;
}

.product form {
    width: 100%;
    padding: 5px;
        /* FLEX */
        display: flex;
        flex-direction: column;
        justify-content: space-between;

}

.product form .product-name {
    font-weight: bold;
    font-size: 1.1rem;
}

.product form .add-button{
    width: 100%;
    color: #fff;
    background-color: var(--cl-gn);
    border-radius: 6px;
    padding: 10px 5px;
    border: none;
    text-align: center;
    font-size: 0.7rem;
    white-space: nowrap;
}

.product .main-img{
    width: 100%;
    aspect-ratio: 1/1;
    box-shadow: 0 0 4px #0000002c;
}

.product .sec-img{
    border: 1px solid black;
    height: 4em;
    width: 4em;
    position: absolute;
    top: 2px;
    right: 2px;
}

.product-imgs span {
    font-size: 3rem;
    width: 100%;
    height: 20vh;
        display: flex;
        justify-content: center;
        align-items: center;
}

.specs{
    color: var(--cl-gr);
    font-size: 0.67rem;
    margin: 5px 0 auto;
}

.price{
    margin: auto 0;
    font-weight: bold;
}


/* .product .outOfStock{
    background-image: url("../../../public/Imgs/no_queda.jpg");
    background-size: cover;
    z-index: 999;
    width: calc(50% - 1.35em);
    height: 30vh;
    position: absolute;
    background-color: rgba(192, 192, 192, 0.5);

        FLEX
        display: flex;
        justify-content: center;
        align-items: center;
} */