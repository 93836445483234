.files-main {
    min-height: 77vh;
    margin-top: 3rem;
        /* FLEX */
        display: flex;
        flex-direction: column;
        align-items: center;
}

.files-main button{
    height: 3rem;
    width: 60%;
    font-size: 20px;
    margin: 2rem 0;
}