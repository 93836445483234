.main-cart{
    padding: 1rem 0;
    background-color: white;
    border-radius: 12px;
    min-height: 91vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-cart h2{
    padding-top: 0.4rem;
}

.main-cart .ta-div{
    margin-top: 1rem;
    padding: 10px;
    width: calc(100% - 2rem);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.534);
    display: flex;
    justify-content: center;
    align-items: center;

}

.main-cart textarea{
    width: 100%;
    height: 13vh;
    font-size: 1rem;
    padding: 7px 10px; 
    border: none;
    border-radius: 8px;
    background-color: var(--cl-gr);
}

.main-cart .shoping-date{
    margin: 1em 0.5rem;
        /* FLEX */
        display: flex;
        flex-direction: column;
        align-items: center;
}

.main-cart .date-picker, .main-cart .date-placeholder{
    width: 75%;
    margin: 0.5rem 2.2rem 0;
    text-align: center;
    height: 7vh;
    border-radius: 8px;
}

.main-cart .schedual{
    margin-top: auto;
    width: 80vw;
    color: var(--cl-gn);
    font-weight: 600;

    display: flex;
    justify-content: space-between;
}

.main-cart .total{
    margin-top: auto;
    width: 80vw;
    color: var(--cl-gn);
    font-weight: 600;
    border-bottom: 2px solid rgb(219, 219, 219);
    padding-bottom: 0.4rem;

    display: flex;
    justify-content: space-between;
}

.main-cart .button{
    font-size: 2em;
    width: calc(100% - 4rem);
    height: 4rem;
    margin: auto 2rem 0.5rem;   
    background-color: var(--cl-gn); 
    border: none;
    color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.615);
}

.main-cart .warning {
    width: 100%;
    text-align: center;
    color: red;
    font-size: 14px;
}