.carousel {
    overflow: hidden;
}

.inner {
    white-space: nowrap;
    transition: transform 1s;
}

.carousel-item {
    margin: 0 1em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: auto;
    color: #fff;
}

.indicators {
    display: flex;
    justify-content: center;
    align-items: center;
}

.indicators > button {
    margin: 5px;
    /* aspect-ratio: 1/1; */
}

.indicators .round{
    width: 1em;
    height: 1em;
    border: 0px;
}

.indicators > button.active {
    background-color: green;
    color: #fff;
}
