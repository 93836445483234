.news{
    padding: 1em 0;
    max-width: 100vw;
    min-height: 77vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.news a{
    max-width: 100vw;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
}

.news img{
    width: 100%;
    /* height: 60vh; */
    box-shadow: 0 -1px 10px #0000006a;
    margin-top: 1rem;
    margin-bottom: -5px;
}

.news h2{
    white-space: wrap; 
    padding: 0 0.4rem;
    margin-top: 0.5rem;
    color: var(--cl-gn);
}