.vertical-counter {
    z-index: 998;
        /* FLEX */
        display: flex;
        justify-content: center;
        align-items: center;
}

.vertical-counter span{
    font-size: 1.3em;
    width: 33.3%;
    text-align: center;
}

.vertical-counter button{
    font-size: 1.3em;
    width: 33.3%;
}

.horizontal-counter{
    z-index: 998;
        /* FLEX */
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
        justify-content: space-around;
}

.horizontal-counter p{
    font-size: 1.5em;
    text-align: center;
}

.horizontal-counter button{
    font-size: 1.1em;
    width: 33.3%;
}